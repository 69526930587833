import React from 'react';
import * as repr from '../../api/types';
import t from '../../localization/i18n';

interface PropsShape {
    report: repr.Report;
}

export default class ImportantInfoText extends React.Component<PropsShape> {
    render() {
        const { investmentType, includeGIA, productType } = this.props.report;

        return (
		<>
                {
                (includeGIA || productType === "GIF")
                    ? t(`report.importantInfo.textInvestmentPlus`)
                    : t(`report.importantInfo.text${investmentType}`)
                }
            </>
        );
    }
}