/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import t from '../../localization/i18n';
import * as repr from '../../api/types';
import theme from '../../styles/theme.pdf.json';

interface PropsShape {
    proposalName: string
    advisorFirstName: string
    advisorLastName: string
    report: repr.Report
}

interface KeyValuePairPropsShape {
    label: string
    value: string
}

function KeyValuePair(props: KeyValuePairPropsShape) {
    return (
        <p css={css`font-size: 15pt;`}>
            <span css={css`
                font-weight: ${theme['$font-weight-xbold']};
                margin-right: 0.5em;
            `}>
                {props.label}
            </span>
            <span css={css`font-weight: ${theme['$font-weight-light']};`}>
                {props.value}
            </span>
        </p>
    );
}

export default function CoverPage(props: PropsShape) {

    const report = props.report
    
    let bgImage;
    let bgSize= "cover";
    let bgPosition = "unset";

    if(report.investmentType === 'MMF'){
        bgImage = `url(img/mmf/cover-image.jpg)`;
    }else if(report.investmentType === 'Seg'){
        if(report.productType === "GIF"){
            bgImage = `url(img/gia/cover-edit.jpg)`;
        }else{
            bgImage = `url(img/seg/cover-edit.jpg)`;
            bgPosition = `-630px 0`;
        }
    }else{
        bgImage = `url(img/gia/cover-image.jpg)`;
    }

    return (
        <section
            className="page-full"
            css={css`
                page: noRunningElements;
                background-image: ${bgImage};
                background-repeat: no-repeat;
                background-size: ${bgSize};
                background-position: ${bgPosition};
                display: flex;
                flex-direction: row-reverse;
            `}
        >
            <div
                className="main-full"
                css={css`
                    padding: calc(${theme['$page-margin-t']})
                        calc(${theme['$page-margin-h']})
                        calc(${theme['$page-margin-b']})
                        ${theme['$page-margin-h']};
                    width: calc(5.5in);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: ${theme['$color-white']};
                `}
            >
                <img
                    css={css`height: 0.73in;`}
                    alt={t('report.mimLogo')}
                    src={`img/manulife_brand-${t('lang')}.svg`}
                />

                <h1 className="section-title cover-title" css={css`font-size: 43pt; margin-left: 54.7pt;`}>
                    {t('report.cover.heading')}
                    <br/>
                    <span css={css`
                        font-size: 32pt;
                        font-weight: ${theme['$font-weight-light']};
                    `}>
                        {props.proposalName}
                    </span>
                </h1>

                <div css={css`margin-bottom: 1.8in; margin-left: 57pt;`}>
                    <KeyValuePair
                        label={t('report.cover.preparedBy')}
                        value={`${props.advisorFirstName} ${props.advisorLastName}`}
                    />
                    <KeyValuePair
                        label={t('report.cover.date')}
                        value={t(new Date().toISOString(), 'dateLong', 'dash')}
                    />
                </div>
            </div>
        </section>
    );
}
