/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import feesStyles from './Fees.styles';

interface PropsShape {
    report: repr.Report
}

export default class Fees extends React.Component<PropsShape> {
    private buildFeesTable = (client: repr.ReportClient, isGIF: boolean, stream: repr.investmentTypes, index: number, isFrenchLocale: boolean) => (
        <>
            <table className='table centered'>
                <thead>
                    <tr>
                        <th>{client.fullName}</th>
                        <th>
                            {t('report.fees.blendedMER')}
                            <sup>{t('report.fees.merDisclaimerAnnMarker')}</sup>
                        </th>
                        {!isGIF && <th>{t('report.fees.afterTaxMFR')}</th>}
                        {this.props.report.includeServiceFee && (
                            <th>{t('report.fees.accountServiceFee')}</th>
                        )}
                        <th>{t('report.fees.totalFees')}</th>
                    </tr>
                </thead>

                <tbody>
                    {client.accounts.map(a => (
                        <tr key={a.nickname}>
                            <td>{a.nickname}</td>
                            <td>{t(a.blendedMer, 'pctMax2', 'dash')}</td>
                            {!isGIF && <td>{t(a.mfr, 'pct3', 'dash')}</td>}
                            {this.props.report.includeServiceFee && (
                                <td>{t(a.serviceFee, 'pctMax2', 'dash')}</td>
                            )}
                            <td>{t(a.totalFees, 'pctMax2', 'dash')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {   (index === 0) && <div className={`annotations ${!isGIF ? 'first' : ''} ${isFrenchLocale ? 'french-ann' : ''}`}>
                    <p>
                        <sup>{t('report.fees.merDisclaimerAnnMarker')}</sup>{' '}
                        {
                            isGIF ? t(`report.fees.merDisclaimerGIF`) : 
                            stream === "Seg" ?  t(`report.fees.merDisclaimer`) :
                            t('report.fees.merDisclaimerMMF')
                        }
                    </p>
                </div>
            }
        </>
    );

    private MFRTiersTable(stream: repr.investmentTypes) {
        return (
            <>
                <table className='table centered'>
                    <thead>
                        <tr>
                            <th>{t('report.fees.MFRTable.category')}</th>
                            <th>{t('report.fees.MFRTable.250To499')}</th>
                            <th>{t('report.fees.MFRTable.500To999')}</th>
                            <th>{t('report.fees.MFRTable.1MTo5M')}</th>
                            <th>{t('report.fees.MFRTable.5MTo10M')}</th>
                            <th>{t('report.fees.MFRTable.10M')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('report.fees.MFRTable.allQualifying')}</td>
                            <td>{t('report.fees.MFRTable.2BasisPoints')}</td>
                            <td>{t('report.fees.MFRTable.5BasisPoints')}</td>
                            <td>{t('report.fees.MFRTable.7BasisPoints')}</td>
                            <td>{t('report.fees.MFRTable.10BasisPoints')}</td>
                            <td>{t('report.fees.MFRTable.12BasisPoints')}</td>
                        </tr>
                    </tbody>
                </table>

                <div className='annotations second'>
                    <p>
                        {t(`report.fees.MFRTable.disclaimer${stream}`)}
                    </p>
                </div>
            </>
        );
    }

    private isProductTypeGIF = (productType: string) => productType === "GIF";

    private isFrenchLocale = (fundProfiles: repr.FundDetailed[]) => fundProfiles.some(f => f.locale === "fr-CA");

    render() {
        const { report } = this.props;
        const stream = report.investmentType;
        const definitionsPath = `report.fees.definitions.${stream}`;

        return (
            <section id="fees" className="page">
                <div css={feesStyles} className="main section fees-section">
                    <h2 className='section-title cover-title'>
                        {t(`report.fees.heading${this.isProductTypeGIF(report.productType) ? "SegGIF" : stream}`)}
                    </h2>

                    {report.clients.map((c,idx:number) => (
                        <div key={c.clientId} className="table-container">
                            {this.buildFeesTable(c, this.isProductTypeGIF(report.productType), stream, idx, this.isFrenchLocale(report.fundProfiles))}
                        </div>
                    ))}

                    <div className='definitions'>
                        <ul>
                            <li>{report.productType === "GIF" ? t(`${definitionsPath}.blendedMERGIF`) : t(`${definitionsPath}.blendedMER`)}</li>
                            {!this.isProductTypeGIF(report.productType) && 
                                <>
                                    <li>{t(`${definitionsPath}.afterTaxMFR`)}</li>
                                    <li>{t(`${definitionsPath}.assetBandTiers`)}</li>

                                    <li>{this.MFRTiersTable(stream)}</li>
                                </>
                            }

                            {!report.includeServiceFee && (
                                report.productType !== "GIF" ? 
                                <li>{t(`${definitionsPath}.totalFeesWithoutServiceFee`)}</li> :
                                <li>{t(`${definitionsPath}.totalFeesWithoutServiceFeeGIF`)}</li>
                            )}

                            {report.includeServiceFee && (<>
                                <li>{t(`${definitionsPath}.accountServiceFee`)}</li>
                                {report.productType !== "GIF" ? 
                                    <li>{t(`${definitionsPath}.totalFeesWithServiceFee`)}</li> :
                                    <li>{t(`${definitionsPath}.totalFeesWithServiceFeeGIF`)}</li>
                                }
                            </>)}
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}
