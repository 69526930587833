/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import t from '../../localization/i18n';
import * as repr from '../../api/types';
import introductionStyles from './Introduction.styles';

interface PropsShape {
    investmentType: repr.investmentTypes;
    advisorName: string;
    productType: repr.productType;
}

interface StateShape {}

export default class Introduction extends React.Component<PropsShape, StateShape> {
    render() {
        const { investmentType, advisorName, productType } = this.props;

        return (
            <div id="introduction" css={introductionStyles} className="section">
                <h2 className="section-title">{productType === "GIF" ? t(`report.intro.headingGIF`) : t(`report.intro.heading${investmentType}`)}</h2>

                {productType === "GIF" ? t(`report.intro.textGIF`) : t(`report.intro.text${investmentType}`)}

                <p>
                    {t('report.intro.sincerely')}
                    <br />
                    <span className="advisor-name">{advisorName}</span>
                </p>
            </div>
        );
    }
}
