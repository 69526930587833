/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import theme from '../../styles/theme.pdf.json';

interface PropsShape {
    report: repr.Report
}

export default function IntroductionPage(props: PropsShape) {
    const { report } = props;

    let bgImage;
    let bgSize= "contain";
    let bgPosition = "right";

    if(report.investmentType === 'MMF'){
        bgImage = `url(img/mmf/intro-letter-image.jpg)`;
    }else if(report.investmentType === 'Seg'){
                if(report.productType === "GIF") {
                    bgImage = `url(img/gia/intro-edit.jpg)`;
                    bgSize=`cover`;
                }else{
                    bgImage = `url(img/seg/intro-edit.jpg)`;
                    bgPosition = `175px`;
                    bgSize="cover";
                }
    }else{
        bgImage = `url(img/gia/intro-letter-image.jpg)`;
        bgSize=`cover`;
    }

    return (
        <section
            className="page-full"
            css={css`
                page: noRunningElements;
                background-image: ${bgImage};
                background-repeat: no-repeat;
                background-size: ${bgSize};
                background-position: ${bgPosition};
            `}
        >
            <div
                className="main-full"
                css={css`
                    background: ${theme['$color-navy']};
                    color: ${theme['$color-white']};
                    width: calc(5.3in + ${theme['$bleed-size']});
                    height: 100%;
                `}
            >
                <h1
                    className="section-title"
                    css={css`font-size: 30pt;`}
                >
                    {report.productType === "GIF" ? t(`report.intro.headingGIF`) : t(`report.intro.heading${report.investmentType}`)}
                </h1>

                <div className="copy">
                    {report.productType === "GIF" ? t(`report.intro.textGIF`) : t(`report.intro.text${report.investmentType}`)}
                </div>

                <p>
                    {t('report.intro.sincerely')}
                    <br />
                    <span css={css`font-weight: ${theme['$font-weight-xbold']};`}>
                        {report.advisorFirstName} {report.advisorLastName}
                    </span>
                </p>
                {report.productType === "GIF" && <div className='annotations impInfo'>
                    <p>
                        {t(`report.intro.footertextGIF`)}
                    </p>
                </div>}
            </div>
        </section>
    );
}
