/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import * as repr from '../../api/types';
import t from '../../localization/i18n';

interface PropsShape {
    productType: repr.productType;
}

const MPIPDisclosures = () => (
    <>
        <h2 className="section-title cover-title">
            {t('report.disclosures.mpip.segPools.heading')}
        </h2>
        <div className='disclaimers'>{t('report.disclosures.mpip.segPools.text')}</div>

        <h2 className="section-title cover-title">
            {t('report.disclosures.mpip.comparablePools.heading')}
        </h2>
        <div className='disclaimers'>
            {t('report.disclosures.mpip.comparablePools.text')}
        </div>
    </>
);

const GIFDisclosures = () => (
    <>
        <h2 className="section-title cover-title">
            {t('report.disclosures.gif.segPools.heading')}
        </h2>
        <div className='disclaimers'>{t('report.disclosures.gif.segPools.text')}</div>

        <h2 className="section-title cover-title">
            {t('report.disclosures.gif.comparablePools.heading')}
        </h2>
        <div className='disclaimers'>
            {t('report.disclosures.gif.comparablePools.text')}
        </div>
     </>
);

export default class Disclosures extends React.Component<PropsShape> {
 
    render() {
        const { productType } = this.props;
        return (
            <section id="disclosures" className="page">
                <div className="section main disclosures-section">
                    {productType === 'MPIP' && <MPIPDisclosures />}
                    {productType === "GIF" && <GIFDisclosures />}
                </div>
            </section>
        );
    }
}
