/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import _ from 'lodash';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import GrowthChart from '../report/GrowthChart';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import performanceStyles from './AccountPerformance.styles';

interface PropsShape {
    report: repr.Report;
    variant: 'web' | 'pdf';
}

export default class AccountPerformance extends React.Component<PropsShape> {
    private buildCompoundReturnsTable = (account: repr.ReportAccount) => (
        <table className="table centered acc-perf">
            <thead>
                <tr>
                    <th>{t('report.p1yr')}</th>
                    <th>{t('report.p3yr')}</th>
                    <th>{t('report.p5yr')}</th>
                    <th>{t('report.p10yr')}</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>{t(account.compoundReturns1Yr * 100, 'decMax2', 'dash')}</td>
                    <td>{t(account.compoundReturns3Yr * 100, 'decMax2', 'dash')}</td>
                    <td>{t(account.compoundReturns5Yr * 100, 'decMax2', 'dash')}</td>
                    <td>{t(account.compoundReturns10Yr * 100, 'decMax2', 'dash')}</td>
                </tr>
            </tbody>
        </table>
    );

    private getCalendarReturnYears = (yearsToShow = 8) => {
        const currentYear = new Date().getFullYear();
        return _.range(currentYear - yearsToShow, currentYear);
    };

    private buildCalendarReturnsTable = (account: repr.ReportAccount) => {
        const headers = this.getCalendarReturnYears(8);

        return (
            <table className="table centered acc-perf">
                <thead>
                    <tr>
                        {headers.map((h) => (
                            <th key={h}>{h}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        {headers.map((h) => (
                            <td key={h}>
                                {t(account.calendarReturns[h] * 100, 'decMax2', 'dash')}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        );
    };

    private buildHistoricalReturnsChart = (
        clientId: string,
        account: repr.ReportAccount,
        accountIndex: number
    ) => {
        const { variant } = this.props;
        const data = account.historicalReturns.map((h, index) => ({
            x: Date.UTC(h.key.year, h.key.month),
            y: h.value,
            marker: {
                enabled: false,
            },
        }));

        const options: Highcharts.Options = {
            title: { text: '' },
            chart: {
                style: { fontFamily: 'Manulife JH Sans' },
                height: 250,
                marginTop: 40,
                marginBottom: 50,
                marginRight: 32,
            },
            colors: ['#06874e'], // --color-green-dark-1
            credits: { enabled: false },
            xAxis: {
                type: 'datetime',
                // tickInterval: 1000 * 60 * 60 * 24 * 365
                labels: {
                    style: {
                        fontSize: '12px',
                    },
                },
            },
            yAxis: {
                title: { text: null },
                startOnTick: true,
                endOnTick: true,
                maxPadding: 0.25,
                labels: {
                    // eslint-disable-next-line
                    formatter: function () {
                        return t(this.value, 'curMax0');
                    },
                    style: {
                        fontSize: '12px',
                    },
                },
            },
            legend: { enabled: false },
            tooltip: { enabled: false },
            plotOptions: {
                line: {
                    animation: false,
                    enableMouseTracking: false,
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '12px',
                        },
                        align: 'center',
                        crop: false,
                        overflow: 'allow',
                        // Formatter function to show label on last data point
                        // eslint-disable-next-line
                        formatter: function () {
                            const data = this.series.data;
                            const lastDataPoint = data[data.length - 1];
                            const point = this.point;

                            if (point.x === lastDataPoint.x && point.y === lastDataPoint.y) {
                                return t(point.y ? point.y : 0, 'curMax0');
                            }
                        },
                        borderWidth: 0,
                        shadow: false,
                        y: -8,
                    },
                },
            },
            series: [{ type: 'line', data }],
        };

        if (variant === 'pdf') {
            return <GrowthChart id={`${clientId}-${accountIndex}-growth-chart`} data={data} />;
        } else {
            return <HighchartsReact highcharts={Highcharts} options={options} />;
        }
    };

    // start and end dates for the historical returns chart
    private getDateFromAndTo = (account: repr.ReportAccount) => {
        if (account.historicalReturns !== null) {
            const arrayLength = account.historicalReturns.length;

            if (arrayLength <= 0) {
                return { dateFrom: '', dateTo: '' };
            }
        
            // New Date to come in from backend.
            const date = new Date(account.returnsStartDate)
            const formattedDate = date.getUTCDate();

            const inceptionYear: number = date.getFullYear();
            const inceptionMonth: number = date.getMonth();
            const inceptionDate: number = formattedDate;

            const dateFrom = new Date(
                inceptionYear,
                inceptionMonth,
                inceptionDate
            );

            
            const endDate = new Date(account.returnsEndDate);
            const formattedEndDate = endDate.getUTCDate();

            const endYear: number = endDate.getFullYear();
            const enddayMonth: number = endDate.getMonth();
            const enddayDate: number = formattedEndDate;

            const dateTo = new Date(
                endYear,
                enddayMonth,
                enddayDate
            );

            return {
                dateFrom: t(dateFrom.toString(), 'dateLong'),
                dateTo: t(dateTo.toString(), 'dateLong'),
            };
        } else {
            return null;
        }
    };

    render() {
        const { report } = this.props;

        // Report is always assumed to have at least one client and a valid report
        // will always have holdings for each account, which is why the only check
        // here is for accounts
        const performanceAsOfDate =
            report.clients[0].accounts.length > 0
                ? report.clients[0].accounts[0].holdings[0].compoundReturnsEffectiveDate
                : null;

        return report.clients.map((c, clientIndex) =>
            c.accounts.map((a, accountIndex) => {
                const isGiaAccount =
                    report.includeGIA && a.holdings.filter((h) => !h.isGia).length === 0;

                if (isGiaAccount) {
                    return null;
                }

                const dates = this.getDateFromAndTo(a);
                const hasUsedProxyFund = _.some(a.holdings, (h) => h.isProxyFund);
                const disclaimerTextKey = hasUsedProxyFund
                    ? 'report.accountPerf.proxyDisclaimerSeg'
                    : report.productType === 'GIF'
                    ? `report.accountPerf.disclaimerGIF`
                    : `report.accountPerf.disclaimer${report.investmentType}`;

                return (
                    <div
                        id="account-performance"
                        key={c.clientId + accountIndex}
                        css={performanceStyles}
                        className="section account-performance"
                    >
                        <section key={c.clientId} className="page">
                            <div className="main" key={c.clientId + a.nickname}>
                                {clientIndex === 0 && accountIndex === 0 && (
                                    <div className="section-header">
                                        <h2
                                            css={css`
                                                margin: 0.2em 0;
                                                font-size: 20pt;
                                            `}
                                            className="section-title cover-title"
                                        >
                                            {t(
                                                `report.accountPerf.heading${report.investmentType}`
                                            )}
                                        </h2>

                                        {performanceAsOfDate && (
                                            <span className="as-of-date">
                                                {t({
                                                    key: 'report.performanceAsOf',
                                                    values: {
                                                        date: t(performanceAsOfDate, 'dateLong'),
                                                    },
                                                })}
                                            </span>
                                        )}

                                        {report.includeGIA && (
                                            <div className="header-disclaimer">
                                                {t('report.accountPerf.giaDisclaimer')}
                                            </div>
                                        )}
                                    </div>
                                )}

                                <h2 className="subtitle">{c.fullName}</h2>

                                <h3 className="account-title">{a.nickname}</h3>

                                <div className="table-container">
                                    <h4 className="table-title">
                                        {t('report.accountPerf.compoundReturns')}
                                    </h4>
                                    {this.buildCompoundReturnsTable(a)}
                                </div>

                                {a.calendarReturns !== null && <div className="table-container">
                                    <h4 className="table-title">
                                        {t('report.accountPerf.calendarReturns')}
                                    </h4>
                                    {this.buildCalendarReturnsTable(a)}

                                    <p className="disclaimers">
                                        {t('report.accountPerf.youngestFundLogic')}
                                    </p>
                                </div>}

                                { a.historicalReturns !== null && a.historicalReturns.length !== 0 && (
                                    <>
                                        <div className="historical-returns-container">
                                            <h4 className="table-title">
                                                {t('report.accountPerf.historicalReturns')}
                                            </h4>
                                            {this.buildHistoricalReturnsChart(
                                                c.clientId,
                                                a,
                                                accountIndex
                                            )}
                                        </div>

                                        {dates && (
                                            <div className="disclaimers">
                                                {t({
                                                    key: disclaimerTextKey,
                                                    values: {
                                                        dateFrom: dates.dateFrom,
                                                        dateTo: dates.dateTo,
                                                    },
                                                })}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </section>
                    </div>
                );
            })
        );
    }
}
