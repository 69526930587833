/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import * as repr from '../../../api/types';
import t from '../../../localization/i18n';
import Button from '../../Button';
import {
    FieldCurrencyRequired,
    FieldPercent,
    WhenFieldChanges
} from '../../shared/CustomFormFields';
import holdingsSectionStyles from './HoldingsSection.styles';

interface PropsShape {
    client: repr.ClientDetailed
    clientIndex: number
    includeServiceFee: boolean
    includeGia: boolean
    serviceFee: number | null
    errors: { [k: string]: string }
    validatePoolValue: (value: number, isPoolValue: boolean) => { isValid: boolean, minimumAmount: number },
    investmentType: 'MMF' | 'Seg'
}

const roundToWholeNumber = (value: number) => Math.round(Math.abs(value * 100)) / 100;

const GIA_MINIMUMS = {
    daily: 0,
    compound: 1000,
    simpleMonthly: 5000
};

export default class HoldingsSection extends React.Component<PropsShape> {
    private get noHoldingsMessage() {
        return (
            <tr>
                <td colSpan={7}>
                    <span>
                        {t('proposalForm.holdings.noHoldingsMessage')}
                    </span>
                </td>
            </tr>
        );
    }

    private generateTotalMessage =
        (total: number) => `${t('application.total')}: ${t(total || 0, 'cur')}`;

    private validateGiaFundValue = (value: number, minimumAmount: number) => (
        { isValid: value >= minimumAmount, minimumAmount }
    );

    private buildTableRow = (
        name: string,
        index: number,
        remove: (index: number) => void,
        account: repr.Account,
        accountIndex: number,
        isGiaRow: boolean
    ) => {
        const { includeServiceFee, serviceFee, clientIndex, validatePoolValue, investmentType } = this.props;
        const holding = account.holdings[index];

        if (isGiaRow !== holding.isGia) {
            return;
        }

        const commonFields = (
            <>
                <td className="allocation">
                    <FieldPercent
                        name={`${name}.percent`}
                        required={true}
                        decPlaces={0}
                        hiddenLabelKey='proposalForm.percent'
                    />

                    {/* When holding percent changes, update holdings amount */}
                    <WhenFieldChanges
                        field={`${name}.percent`}
                        becomes={account.holdings[index].percent}
                        set={`${name}.amount`}
                        to={Math.round(account.holdings[index].percent as number * account.amount)}
                    />

                    {/* # Update percentage when account or holdings amount changes */}
                    {/* ## avoid dividing by 0, don't change percent if either amount is deleted */}

                    {/* When holding amount changes */}
                    <WhenFieldChanges
                        field={`${name}.amount`}
                        becomes={account.holdings[index].amount}
                        set={`${name}.percent`}
                        to={account.amount === 0
                            ? account.holdings[index].percent
                            : roundToWholeNumber(account.holdings[index].amount / account.amount)
                        }
                    />

                    {/* When account amount changes */}
                    <WhenFieldChanges
                        field={`clients[${clientIndex}].accounts[${accountIndex}].amount`}
                        becomes={account.amount}
                        set={`${name}.percent`}
                        to={account.amount === 0
                            ? account.holdings[index].percent
                            : roundToWholeNumber(account.holdings[index].amount / account.amount)
                        }
                    />
                </td>
                <td className='align-middle delete'>
                    <Button variant="icon" type='button' onClick={() => remove(index)}>
                        <span className="icon icon--delete" aria-hidden="true"/>
                        <span className="sr-only">
                            {t({
                                key: 'proposalForm.alt.deleteAccountHoldingIcon',
                                values: {
                                    accountName: account.nickname,
                                    holdingName: holding.fundName,
                                }
                            })}
                        </span>
                    </Button>
                </td>
            </>
        );

        if (isGiaRow) {
            const giaMinimum = GIA_MINIMUMS[holding.__GiaType];

            return (
                <tr key={index}>
                    <td className='align-middle fund-code' />
                    <td className='align-middle'>{holding.giaType}</td>
                    <td className='align-middle gia-term'>
                        {holding.giaTerm}
                    </td>
                    {includeServiceFee && (
                        <td className="service-fee" />
                    )}

                    <td className="value">
                        <FieldCurrencyRequired
                            name={`${name}.amount`}
                            validator={
                                (value: number) => {
                                    const validation = this.validateGiaFundValue(value, giaMinimum);
                                    return validation.isValid
                                        ? undefined
                                        : t({
                                            key: 'errorMessages.giaMinimumNotMet',
                                            values: { value: t(validation.minimumAmount, 'cur') }
                                        });
                                }
                            }
                            hiddenLabelKey='proposalForm.amount'
                        />
                    </td>

                    {commonFields}
                </tr>
            );
        } else {
            return (
                <tr key={index}>
                    <td className='align-middle fund-code'>{holding.fundServCode || holding.webProfileId}</td>
                    <td className='align-middle'>
                        {(holding.fundName.includes(" - ") && holding.holdingProductType === "mmf") ? holding.fundName.split(" - ")[1] :
                            holding.fundName}
                    </td>
                    <td className='align-middle mer'>{t(holding.originalMer, 'pctMax2')}</td>
                    {includeServiceFee && (
                        <td className="service-fee">
                            <FieldPercent
                                name={`${name}.serviceFee`}
                                hiddenLabelKey='application.serviceFee'
                            />
                            <WhenFieldChanges
                                field='serviceFee'
                                becomes={serviceFee}
                                set={`${name}.serviceFee`}
                                to={serviceFee}
                            />
                        </td>
                    )}

                    <td className="value">
                        <FieldCurrencyRequired
                            name={`${name}.amount`}
                            validator={
                                (value: number) => {
                                    const validation = validatePoolValue(value, true);
                                    const isNonMpipMMF: boolean = investmentType === 'MMF' && holding.holdingProductType !== 'mpip'; 
                                    return (isNonMpipMMF || validation.isValid)
                                        ? undefined
                                        : t({
                                            key: 'errorMessages.poolMinimumNotMet',
                                            values: { value: t(validation.minimumAmount, 'cur') }
                                        });
                                }
                            }
                            hiddenLabelKey='proposalForm.amount'
                        />
                    </td>

                    {commonFields}
                </tr>
            );
        }
    };

    render() {
        const { clientIndex, client, includeServiceFee, includeGia, errors } = this.props;

        return (
            <div css={holdingsSectionStyles}>
                <h2 className='heading'>{t('proposalForm.holdings.heading')}</h2>

                {client.accounts.map((a, accountIndex) => {
                    const holdingsTotal = _.sum(_.map(a.holdings, 'amount'));

                    // Contains error if holding amounts don't add up to account total
                    const errorMessage =
                        errors[`clients[${clientIndex}].accounts[${accountIndex}].holdings`] || '';

                    return (
                        <FieldArray
                            key={`${a.nickname}${accountIndex}`}
                            name={`clients[${clientIndex}].accounts[${accountIndex}].holdings`}
                        >
                            {({ fields }) => (
                                <div className='table-container'>
                                    <h3 className='account-heading'>{a.nickname}</h3>

                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th className="fund-code">
                                                    {t('application.fundCode')}
                                                </th>
                                                <th>{t('application.fund')}</th>
                                                <th className="mer">{t('application.mer')}</th>
                                                {includeServiceFee && (
                                                    <th className="service-fee">
                                                        {t('application.serviceFee')}
                                                    </th>
                                                )}
                                                <th className="value">
                                                    {t('proposalForm.amount')}
                                                </th>
                                                <th className="allocation">
                                                    {t('proposalForm.percent')}
                                                </th>
                                                <td className="delete" />
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {fields.map((name, index) => this.buildTableRow(
                                                name, index, fields.remove, a, accountIndex, false
                                            ))}

                                            {a.holdings.filter(h => !h.isGia).length === 0 &&
                                                this.noHoldingsMessage}
                                        </tbody>
                                    </table>

                                    {includeGia && (
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th className="fund-code" />
                                                    <th>{t('proposalForm.giaSelector.type')}</th>
                                                    <th className="gia-term">
                                                        {t('proposalForm.giaSelector.term')}
                                                    </th>
                                                    {includeServiceFee && (
                                                        <th className="service-fee" />
                                                    )}
                                                    <th className="value">
                                                        {t('proposalForm.amount')}
                                                    </th>
                                                    <th className="allocation">
                                                        {t('proposalForm.percent')}
                                                    </th>
                                                    <td className="delete" />
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {fields.map((name, index) => this.buildTableRow(
                                                    name,
                                                    index,
                                                    fields.remove,
                                                    a,
                                                    accountIndex,
                                                    true
                                                ))}

                                                {a.holdings.filter(h => h.isGia).length === 0 &&
                                                    this.noHoldingsMessage}
                                            </tbody>
                                        </table>
                                    )}

                                    <div className='footer'>
                                        <span className='holdings-total'>
                                            {this.generateTotalMessage(holdingsTotal)}
                                        </span>

                                        <span className='error-message'>{errorMessage}</span>
                                    </div>
                                </div>
                            )}
                        </FieldArray>
                    );
                })}

                {client.accounts.length === 0 &&
                    <span>
                        {t('proposalForm.accounts.noAccountsMessage')}
                    </span>
                }
            </div>
        );
    }
}
