/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import ClientWrapper from './ClientWrapper';
import investmentOverviewStyles from './InvestmentOverview.styles';

interface PropsShape {
    report: repr.Report;
}

interface StateShape {}

export default class InvestmentOverview extends React.Component<PropsShape, StateShape> {
    state: StateShape = {};

    private showGiaDiscliamer() {
        return (
            <p>{t('report.fees.giaDisclaimer')}</p>
        );
    }

    private showMerDisclaimer(investmentType: repr.investmentTypes, productType: repr.productType) {
        const isSegOrGIF = (investmentType === "Seg" && productType === "GIF") ? `GIF` : ``;
        return (
            <p>
                <sup>{t('report.fees.merDisclaimerAnnMarker')}</sup>{' '}
                {investmentType === "Seg" ? t(`report.fees.merDisclaimer${isSegOrGIF}`) : t('report.investmentOverview.MERDisclaimerMMF')}
            </p>
        );
    }

    private renderTotal = (
        holdings: repr.ReportHolding[] | repr.ReportConsolidatedHolding[],
        total: number
    ) => (
        <div className="account-total">
            <div className="bold">{t('application.total')}:</div>
            <div className="bold amount">{t(total, 'cur')}</div>
            <div className="bold percent">{t(_.sum(_.map(holdings, 'percent')), 'pctMax1')}</div>
        </div>
    );

    private buildGiaTable = (holdings: repr.ReportHolding[] | repr.ReportConsolidatedHolding[]) => (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th className="fund-name">{t('report.gia')}</th>
                        <th>{t('report.term')}</th>
                        <th className="value">{t('report.investmentOverview.value')}</th>
                        <th className="allocation">
                            {t('report.investmentOverview.allocation')}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {_.map(holdings, (h) => (
                        <tr key={`${h.fundUniqueId}`}>
                            <td className="left fund-name">{h.giaType}</td>
                            <td>{h.giaTerm}</td>
                            <td className="value">{t(h.amount, 'cur')}</td>
                            <td className="allocation">{t(h.percent as number, 'pctMax1')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </>
    );

    private buildHoldingsTable = (
        includeServiceFee: boolean,
        holdings: repr.ReportHolding[] | repr.ReportConsolidatedHolding[],
        total: number,
        nickname: string | undefined,
        investmentType: repr.investmentTypes,
    ) => (
        <>
            <table className="table">
                <thead>
                    {nickname && <tr><th className='account-title' colSpan = {includeServiceFee ? 6 : 5}>{nickname}</th></tr>}
                    <tr>
                        <th className="fund-name">{t('report.fund')}</th>
                        <th>
                            {t(
                                `report.${this.props.report.investmentType === 'Seg'
                                    ? 'loadType'
                                    : 'series'
                                }`
                            )}
                        </th>
                        <th>
                            {t('application.mer')}
                            <sup>{t('report.fees.merDisclaimerAnnMarker')}</sup>
                        </th>
                        {includeServiceFee && <th>{t(`application.serviceFee${investmentType}`)}</th>}
                        <th className="value">{t('report.investmentOverview.value')}</th>
                        <th className="allocation">
                            {t('report.investmentOverview.allocation')}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {_.map(holdings, (h) => (
                        <tr key={`${h.fundUniqueId}`}>
                            <td className="left fund-name">{h.fundName}</td>
                            <td>
                                {this.props.report.investmentType === 'Seg' ? h.load : h.className}
                            </td>
                            <td>{t(h.originalMer, 'pctMax2')}</td>
                            {includeServiceFee && <td>{t(h.serviceFee, 'pctMax2')}</td>}
                            <td className="value">{t(h.amount, 'cur')}</td>
                            <td className="allocation">{t(h.percent as number, 'pctMax1')}</td>
                        </tr>
                    ))}
                    {/* <tr>
                        <td colSpan={includeServiceFee ? 3 : 2}></td>
                        <td className="bold total">{t('application.total')}:</td>
                        <td className="bold amount">{t(total, 'cur')}</td>
                        <td className="bold percent">{t(_.sum(_.map(holdings, 'percent')), 'pctMax1')}</td>
                    </tr> */}
                </tbody>
            </table>

        </>
    );

    render() {
        const { report } = this.props;
        const consolidatedViewTitle = t('report.consolidated');

        return (
            <section className="page">
                <div
                    id="investment-overview"
                    className="section main"
                    css={investmentOverviewStyles}
                >
                    <h2 className="section-title cover-title">{t('report.investmentOverview.heading')}</h2>

                    {_.map(report.clients, (c, i) => (
                        <ClientWrapper key={c.clientId} title={c.fullName}>
                            {_.map(c.accounts, (a, idx) => {
                                const regularHoldings = a.holdings.filter((h) => !h.isGia);
                                const giaHoldings = a.holdings.filter((h) => h.isGia);

                                return (
                                    <div key={a.nickname} className="table-container tc-extra-spacing">
                                        {regularHoldings.length > 0 &&
                                            this.buildHoldingsTable(
                                                report.includeServiceFee,
                                                regularHoldings,
                                                a.amount,
                                                a.nickname,
                                                report.investmentType
                                            )
                                        }

                                        {giaHoldings.length > 0 && this.buildGiaTable(giaHoldings)}

                                        {this.renderTotal(a.holdings, a.amount)}

                                        {(i === 0 && idx === 0) && <div className="annotations">
                                            {regularHoldings.length > 0 && this.showMerDisclaimer(report.investmentType, report.productType)}
                                            {giaHoldings.length > 0 && this.showGiaDiscliamer()}
                                        </div>}
                                    </div>
                                );
                            })}

                            {c.accounts.length > 1 && (
                                <>
                                    <div className="table-container tc-extra-spacing">
                                        {c.consolidatedHoldings.filter(h => !h.isGia).length > 0 &&
                                            this.buildHoldingsTable(
                                                report.includeServiceFee,
                                                c.consolidatedHoldings.filter((h) => !h.isGia),
                                                c.amount,
                                                consolidatedViewTitle,
                                                report.investmentType
                                            )
                                        }

                                        {c.consolidatedHoldings.filter((h) => h.isGia).length > 0 &&
                                            this.buildGiaTable(
                                                c.consolidatedHoldings.filter((h) => h.isGia)
                                            )
                                        }

                                        {this.renderTotal(c.consolidatedHoldings, c.amount)}

                                        <div className="annotations">
                                            {c.consolidatedHoldings.filter(
                                                h => !h.isGia
                                            ).length > 0 &&
                                                this.showMerDisclaimer(report.investmentType, report.productType)
                                            }
                                            {c.consolidatedHoldings.filter(
                                                h => h.isGia
                                            ).length > 0 &&
                                                this.showGiaDiscliamer()
                                            }
                                        </div>
                                    </div>

                                </>
                            )}

                            {i !== report.clients.length - 1 && <div className="divider" />}
                        </ClientWrapper>
                    ))}
                </div>
            </section>
        );
    }
}