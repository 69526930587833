import uuidv4 from 'uuid/v4';
import * as repr from './types';

export default class Resource<T> {
    data: T;

    constructor(data: T) {
        this.data = data;
    }
}

export interface RequestData {
    [key: string]: string | number | boolean | object | undefined | null;
}

interface RequestOpts {
    params?: RequestData;
    authToken?: string | null;
}

function encodeQueryParams(qp: RequestData) {
    return Object.keys(qp)
        .map(k => {
            if (qp[k] !== null) {
                return (
                    `${encodeURIComponent(k)}=${
                        encodeURIComponent(qp[k] as string | number | boolean)
                    }`
                );
            }

            return null;
        })
        .join('&');
}

export function get<T>(
    uri: string,
    opts: RequestOpts = {}
): Promise<Resource<T>> {
    return makeHTTPRequest(uri, 'GET', null, opts);
}

export function post<T>(
    uri: string,
    body?: RequestData | null,
    opts: RequestOpts = {}
): Promise<Resource<T>> {
    return makeHTTPRequest(uri, 'POST', body, opts);
}

export function delete_<T>(
    uri: string,
    body?: RequestData | null,
    opts: RequestOpts = {}
): Promise<Resource<T>> {
    return makeHTTPRequest(uri, 'DELETE', body, opts);
}

export function put<T>(
    uri: string,
    body?: RequestData | null,
    opts: RequestOpts = {}
): Promise<Resource<T>> {
    return makeHTTPRequest(uri, 'PUT', body, opts);
}

async function makeHTTPRequest<T>(
    uri: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT',
    body?: RequestData | null,
    opts: RequestOpts = {}
): Promise<Resource<T>> {
    const baseUrl = '/proposal-builder';
    let url = `${baseUrl}${uri}`;

    // All api calls now have a unique uuid as a query parameter to avoid
    // calls being cached in IE
    url += `?cacheBustId=${uuidv4()}`;

    if (opts.params) {
        url += `&${encodeQueryParams(opts.params)}`;
    }

    const headers: HeadersInit = {};
    const init: RequestInit = {
        method,
        credentials: 'include',
        headers
    };

    if (body) {
        headers['Content-Type'] = 'application/json';
        init.body = JSON.stringify(body);
    }

    if (opts.authToken) {
        headers.Authorization = `Bearer ${opts.authToken}`;
    }

    if (method === 'GET') {
        return fetch(url, init)
            .then(r => Promise.all([r, r.json()]))
            .then(([resp, json]) => {
                if (resp.status > 400) {
                    throw new Error(json);
                }

                return new Resource(json as T);
            });
    }

    return fetch(url, init)
        .then(r => Promise.all([r, r.text()]))
        .then(([resp, text]) => {
            if (resp.status > 400) {
                throw new Error(text);
            }

            return new Resource({} as T);
        });
}

export const getProposals = (
    authToken: string | null,
    type?: repr.investmentTypes
): Promise<Resource<repr.ProposalsPayload>> => {
    const params: RequestData = {};

    if (type) {
        params.type = type;
    }

    return get('/api/Proposals', { params, authToken });
};

export const getProposal = (
    authToken: string | null,
    proposalId: string,
    locale: string
): Promise<Resource<repr.ProposalDetailed>> => {
    const params: RequestData = {};

    params.locale = locale;
    
    return get(`/api/Proposals/${proposalId}`, { params, authToken })};

export const postProposal = (
    authToken: string | null,
    userId: string | null,
    data: Partial<repr.ProposalDetailed>
): Promise<Resource<{}>> => {
    // If id is part of the data object then this will update the
    // existing proposal else it will create a new one
    const body = { ...data, createdBy: userId };
    return post('/api/Proposals', body, { authToken });
};

export const deleteProposal = (
    authToken: string | null,
    proposalId: string
): Promise<Resource<{}>> => delete_(
    `/api/Proposals/${proposalId}`, { authToken }
);

export const getFunds = (
    authToken: string | null,
    streamType: repr.investmentTypes,
    isFeeBased: boolean,
    locale: string
): Promise<Resource<repr.FundsPayload>> => {
    const params: RequestData = {};

    // TODO: api expects lowercase streamType but everywhere else
    // uses 'MMF' | 'Seg' (api change needed)
    params.streamType = streamType.toLowerCase();
    params.isFeeBased = isFeeBased;
    params.locale = locale;

    return get('/api/Funds', { params, authToken });
};

export const getGiaFunds = (
    authToken: string | null,
    locale: string
): Promise<Resource<repr.FundsPayload>> => {
    const params: RequestData = {};
    params.locale = locale;

    return get('/api/Funds/Gia', { params, authToken });
};

export const getEtfFunds = (
    authToken: string | null,
    locale: string
): Promise<Resource<repr.FundsPayload>> => {
    const params: RequestData = {};
    params.locale = locale;

    return get('/api/Funds/etf', { params, authToken });
};

export const postLocalization = (
    authToken: string | null,
    locale: string,
    returnUrl?: string
): Promise<Resource<{}>> => {
    const params: RequestData = {};
    params.culture = locale;

    if (returnUrl) {
        params.returnUrl = returnUrl;
    }

    return post('/Localization', {}, { params, authToken });
};

export const getReport = (
    authToken: string | null,
    proposalId: string,
    clientId: string | null,
    locale: 'en-US' | 'fr-CA'
): Promise<Resource<repr.Report>> => {
    const params: RequestData = {};

    if (clientId) {
        params.clientId = clientId;
    }

    return get(`/api/Reports/${proposalId}/${locale}/view`, { params, authToken });
};

export const getPDFReportId = (
    authToken: string | null,
    proposalId: string,
    clientId: string | null
): Promise<Resource<repr.PDFReportPayload>> => {
    const params: RequestData = {};

    if (clientId) {
        params.clientId = clientId;
    }

    return get(`/api/Reports/${proposalId}/pdfs`, { params, authToken });
};

export const getPDFReportUrl = (
    authToken: string | null,
    reportlId: string,
    locale: 'en-US' | 'fr-CA'
): Promise<Resource<repr.ReportUrlPayload>> => get(
    `/api/Reports/${reportlId}/${locale}/download`,
    { authToken }
);

export const getCurrentUser = (
    authToken: string | null
): Promise<Resource<repr.User>> => get(
    '/api/Users/currentUser',
    { authToken }
);
