/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import Button from '../Button';
import { FieldBooleanRadio, FieldPercent, WhenFieldChanges } from '../shared/CustomFormFields';
import ModalBase from '../shared/ModalBase';
import configurationStyles from './Configuration.styles';

interface PropsShape {
    values: repr.ProposalDetailed
    changeFormValue: (name: string, value?: any) => void
}

interface StateShape {
    isFeeBasedModalSettings: { isVisible: boolean, valueToConfirm?: boolean }
    includeGiaModalSettings: { isVisible: boolean, valueToConfirm?: boolean }
    productTypeModalSettings: { isVisible: boolean, valueToConfirm?: boolean }
}


const provinceList = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

export default class Configuration extends React.Component<PropsShape, StateShape> {

    state: StateShape = {
        isFeeBasedModalSettings: { isVisible: false },
        includeGiaModalSettings: { isVisible: false },
        productTypeModalSettings: { isVisible: false },
    };

    componentDidMount() {
        const { values } = this.props;
        (values.productType === "MPIP" || values.productType === null) ? this.includeProductType('MPIP') : this.includeProductType('GIF');
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { values } = this.props;

        if (prevProps.values !== values) {
            (values.productType === "MPIP" || values.productType === null) ? this.includeProductType('MPIP') : this.includeProductType('GIF');
        }
    }


    private clearAllHoldings = () => {
        const { changeFormValue, values } = this.props;

        values.clients.forEach((c, clientIndex) => {
            c.accounts.forEach((a, accountIndex) => {
                changeFormValue(
                    `clients[${clientIndex}].accounts[${accountIndex}].holdings`,
                    []
                );
            });
        });
    }

    private clearAllGiaFunds = () => {
        const { changeFormValue, values } = this.props;

        values.clients.forEach((c, clientIndex) => {
            c.accounts.forEach((a, accountIndex) => {
                changeFormValue(
                    `clients[${clientIndex}].accounts[${accountIndex}].holdings`,
                    a.holdings.filter(h => !h.isGia)
                );
            });
        });
    }

    private closeIsFeeBasedModal = () => {
        this.setState({ isFeeBasedModalSettings: { isVisible: false } });
    }

    private get isFeeBasedModal() {
        const { changeFormValue } = this.props;
        const { isFeeBasedModalSettings } = this.state;

        const header =
            <React.Fragment>{t('proposalForm.config.changeFeeBasedConfirmation')}</React.Fragment>;

        const submitButtons = (
            <>
                <Button onClick={this.closeIsFeeBasedModal}>
                    {t('application.cancel')}
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    onClick={() => {
                        changeFormValue('isFeeBased', isFeeBasedModalSettings.valueToConfirm);
                        this.clearAllHoldings();
                        this.closeIsFeeBasedModal();
                    }}
                >
                    {t('application.yes')}
                </Button>
            </>
        );

        return (
            <ModalBase
                header={header}
                footer={submitButtons}
                isVisible={isFeeBasedModalSettings.isVisible}
                toggleModal={this.closeIsFeeBasedModal}
            />
        );
    }

    private changeIsFeeBasedSettings = (value: boolean) => {
        const { values, changeFormValue } = this.props;

        if (value === values.isFeeBased) {
            return;
        }

        const doesProposalHaveHoldings =
            _.some(values.clients, c => _.some(c.accounts, a => a.holdings.length !== 0));

        if (doesProposalHaveHoldings) { // this hides/shows the modal
            this.setState(
                { isFeeBasedModalSettings: { isVisible: true, valueToConfirm: value } }
            );
        } else {
            changeFormValue('isFeeBased', value);
        }
    }

    private closeIncludeGiaModal = () => {
        this.setState({ includeGiaModalSettings: { isVisible: false } });
    }

    private closeChangeProductModal = () => {
        this.setState({productTypeModalSettings: {isVisible: false}})
    }

    private get includeGiaModal() {
        const { changeFormValue } = this.props;
        const { includeGiaModalSettings } = this.state;

        const header =
            <React.Fragment>
                {t('proposalForm.config.changeIncludeGiaConfirmation')}
            </React.Fragment>;

        const submitButtons = (
            <>
                <Button onClick={this.closeIncludeGiaModal}>
                    {t('application.cancel')}
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    onClick={() => {
                        changeFormValue('includeGIA', includeGiaModalSettings.valueToConfirm);
                        this.clearAllGiaFunds();
                        this.closeIncludeGiaModal();
                    }}
                >
                    {t('application.yes')}
                </Button>
            </>
        );

        return (
            <ModalBase
                header={header}
                footer={submitButtons}
                isVisible={includeGiaModalSettings.isVisible}
                toggleModal={this.closeIncludeGiaModal}
            />
        );
    }
    
    private changeProductTypeModal() {
        const { values, changeFormValue } = this.props;
        const { productTypeModalSettings } = this.state;

        const header =
            <React.Fragment>
                {t('proposalForm.config.changeProductConfirmation')}
            </React.Fragment>;

        const submitButtons = (
            <>
                <Button onClick={this.closeChangeProductModal}>
                    {t('application.cancel')}
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    onClick={() => {
                        changeFormValue('productType', values.productType === 'GIF' ? 'MPIP': 'GIF');
                        this.clearAllHoldings();
                        this.closeChangeProductModal();
                    }}
                >
                    {t('application.yes')}
                </Button>
            </>
        );

        return (
            <ModalBase
                header={header}
                footer={submitButtons}
                isVisible={productTypeModalSettings.isVisible}
                toggleModal={this.closeChangeProductModal}
            />
        );
    }

    private changeIncludeGiaSettings = (value: boolean) => {
        const { values, changeFormValue } = this.props;

        if (value === values.includeGIA) {
            return;
        }

        const doesProposalHaveGiaHoldings =
            _.some(
                values.clients,
                c => _.some(c.accounts, a => a.holdings.filter(h => h.isGia).length !== 0)
            );

        if (doesProposalHaveGiaHoldings) { // this hides/shows the modal
            this.setState(
                { includeGiaModalSettings: { isVisible: true, valueToConfirm: value } }
            );
        } else {
            changeFormValue('includeGIA', value);
        }
    }

    private includeProductType = (value: repr.productType) => {
        const { changeFormValue } = this.props;
        changeFormValue('productType', value)
    }
    
    private onChangeProductType = () => this.setState({productTypeModalSettings: {isVisible: true}})

    render() {
        const { values } = this.props;
        return (
            <div css={configurationStyles}>
                <h2 className='heading'>
                    <span className='bold'>
                        {t('proposalForm.config.title')}
                    </span>{' '}
                    {t('proposalForm.config.titleNote')}
                </h2>

                {values.investmentType === 'Seg' && (
                    <div className={'product-row'}>
                        {t('proposalForm.config.productType')}
                        <div className="product-group">
                            <label>
                                <input
                                    type="radio"
                                    checked={values.productType === 'MPIP'}
                                    onChange={()=>this.onChangeProductType()}
                                />{' '}
                                {t('application.mpip')}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    checked={values.productType === 'GIF'}
                                    onChange={()=>this.onChangeProductType()}
                                />{' '}
                                {t('application.gif')}
                            </label>
                        </div>
                        {this.changeProductTypeModal()}
                    </div>
                )}

                {values.investmentType === 'Seg' && (
                    <div className='input-row'>
                        <div>
                            {t('proposalForm.config.giasIncludedQuestion')}
                        </div>
                        <div className='input-group'>
                            <label>
                                <input
                                    type="radio"
                                    checked={values.includeGIA}
                                    onChange={() => this.changeIncludeGiaSettings(true)}
                                />
                                {' '}{t('application.yes')}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    checked={!values.includeGIA}
                                    onChange={() => this.changeIncludeGiaSettings(false)}
                                />
                                {' '}{t('application.no')}
                            </label>
                        </div>

                        {this.includeGiaModal}
                    </div>
                )}

                <div className='input-row'>
                    <div>
                        {t('proposalForm.config.feeBasedQuestion')}
                    </div>
                    <div className='input-group'>
                        <label>
                            <input
                                type="radio"
                                checked={values.isFeeBased}
                                onChange={() => this.changeIsFeeBasedSettings(true)}
                            />
                            {' '}{t('application.yes')}
                        </label>
                        <label>
                            <input
                                type="radio"
                                checked={!values.isFeeBased}
                                onChange={() => this.changeIsFeeBasedSettings(false)}
                            />
                            {' '}{t('application.no')}
                        </label>
                    </div>
                    {/* Set includeServiceFee to false when isFeeBased is false */}
                    <WhenFieldChanges
                        field="isFeeBased"
                        becomes={false}
                        set="includeServiceFee"
                        to={false}
                    />
                    {this.isFeeBasedModal}
                </div>

                {values.isFeeBased &&
                    <div className='input-row'>
                        <div>
                            {t('proposalForm.config.serviceFeeQuestion')}
                        </div>
                        <div className='input-group'>
                            <fieldset>
                                <legend className='sr-only'>
                                    {t('proposalForm.config.serviceFeeQuestion')}
                                </legend>
                                <label>
                                    <FieldBooleanRadio name="includeServiceFee" value={true} />
                                    {' '}{t('application.yes')}
                                </label>
                                <label>
                                    <FieldBooleanRadio name="includeServiceFee" value={false} />
                                    {' '}{t('application.no')}
                                </label>
                            </fieldset>
                        </div>
                    </div>
                }

                {values.isFeeBased && values.includeServiceFee && (
                    <div className='input-row service-fee'>
                        <FieldPercent name="serviceFee" labelKey="proposalForm.config.fee" />
                    </div>
                )}

                {values.isFeeBased && values.includeServiceFee && (
                    <div className='input-row'>
                        <label htmlFor="province-selector">
                            {t('proposalForm.config.provinceQuestion')}
                        </label>
                        <div className='input-group province-dropdown'>
                            <Field
                                id='province-selector'
                                name="province"
                                component="select"
                                initialValue={values.province || provinceList[0]}
                            >
                                {provinceList.map(p => (
                                    <option key={p} value={p}>
                                        {p}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>
                )}

                <div className='input-row'>
                    <div>
                        {t('proposalForm.config.includeFundProfiles')}
                    </div>
                    <div className='input-group'>
                        <fieldset>
                            <legend className='sr-only'>
                                {t('proposalForm.config.includeFundProfiles')}
                            </legend>
                            <label>
                                <FieldBooleanRadio name="includeFundProfiles" value={true} />
                                {' '}{t('application.yes')}
                            </label>
                            <label>
                                <FieldBooleanRadio name="includeFundProfiles" value={false} />
                                {' '}{t('application.no')}
                            </label>
                        </fieldset>
                    </div>
                </div>

                <div className='input-row'>
                    <div>
                        {t('proposalForm.config.includeFundFacts')}
                    </div>
                    <div className='input-group'>
                        <fieldset>
                            <legend className='sr-only'>
                                {t('proposalForm.config.includeFundFacts')}
                            </legend>
                            <label>
                                <FieldBooleanRadio name="includeFundFacts" value={true} />
                                {' '}{t('application.yes')}
                            </label>
                            <label>
                                <FieldBooleanRadio name="includeFundFacts" value={false} />
                                {' '}{t('application.no')}
                            </label>
                        </fieldset>
                    </div>
                </div>

                <div className='input-row'>
                    <div>
                        {t('proposalForm.config.overrideMinimums')}
                    </div>
                    <div className='input-group'>
                        <fieldset>
                            <legend className='sr-only'>
                                {t('proposalForm.config.overrideMinimums')}
                            </legend>
                            <label>
                                <FieldBooleanRadio name="overrideMinimums" value={true} />
                                {' '}{t('application.yes')}
                            </label>
                            <label>
                                <FieldBooleanRadio name="overrideMinimums" value={false} />
                                {' '}{t('application.no')}
                            </label>
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }
}
