/** @jsx jsx */
import _ from 'lodash';
import { jsx } from '@emotion/core';
import React from 'react';
import { Link } from 'react-scroll';
import t from '../../localization/i18n';
import * as repr from '../../api/types';

import scrollNavStyles from './ScrollNav.styles';

interface PropsShape {
    investmentType: repr.investmentTypes;
    proxyFundsLength: number;
    fundProfiles: repr.FundDetailed[];
    isGiaReport: boolean;
    includeGIA: boolean;
    productType: repr.productType;
}

export default class ScrollNav extends React.Component<PropsShape> {
    render() {
        const {
            investmentType,
            proxyFundsLength,
            fundProfiles,
            isGiaReport,
            includeGIA,
            productType,
        } = this.props;

        //Check for fund Specific Disclosures
        const hasFundSpecificDisclosures = fundProfiles.some(fund => fund.fundSpecificDisclosures !== null);

        // Some nav items are exclusive to one of the streams
        // Some are for both the streams, but have different resources
        const sections = [
            { id: 'introduction', isVisible: true },
            { id: 'investment-overview', isVisible: true },
            { id: 'fund-performance', isVisible: !isGiaReport },
            {
                id: 'comparable-funds',
                isVisible: proxyFundsLength > 0,
            },
            {
                id: 'account-performance',
                isVisible: !isGiaReport,
                key: investmentType === 'MMF' ? 'accountPerformance' : 'contractPerformance',
            },
            {
                id: 'account-allocation',
                isVisible: true,
                key: investmentType === 'MMF' ? 'accountAllocation' : 'contractAllocation',
            },
            {   id: 'pool-details', 
                isVisible: fundProfiles && fundProfiles.length > 0,
                key: (productType === "GIF") ? 'fundDetailsGIF' : (investmentType === "MMF") ? 'fundDetails' : 'poolDetails'
            },
            { id: 'gia-marketing-section', isVisible: includeGIA },
            { id: 'fees', isVisible: true },
            {   id: 'program-details', 
                isVisible: true,
                key: (productType === "GIF") ? 'contractDetails' : 'programDetails'
            },
            { id: 'benefits', isVisible: true },
            { id: 'fundSpecificDisclosures', isVisible: hasFundSpecificDisclosures},
            { id: 'important-info', isVisible: true },
            { id: 'disclosures', isVisible: investmentType === 'Seg' },
        ];

        return (
            <div id="scroll-nav" css={scrollNavStyles}>
                {sections.map(s => s.isVisible
                    ? (
                        <Link
                            key={s.id}
                            activeClass="active"
                            to={s.id}
                            spy={true}
                            smooth={true}
                            offset={-85}
                            duration={300}
                        >
                            <div className="nav-item">
                                <span className="indicator" />
                                <span className="name">
                                    {t(`report.navigation.${s.key || _.camelCase(s.id)}`)}
                                </span>
                            </div>
                        </Link>
                    ) : null
                )}
            </div>
        );
    }
}
