/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import * as Highcharts from 'highcharts';
import * as repr from '../../api/types';
import HighchartsReact from 'highcharts-react-official';
import t from '../../localization/i18n';

interface PropsShape {
    allocations: { name: string, pct: number }[]
    title: string
    size: number,
    productType: repr.productType,
    investmentType: repr.investmentTypes
}

export default class AllocationChart extends React.Component<PropsShape> {
    render() {
        const { allocations, title, size, productType, investmentType } = this.props;

        const data = allocations.map(a => ({ name: a.name, y: a.pct }));
        
        // Remember to cross-check orderedColors variable in pie-chart.js to have same value as this to maintain
        // uniformity in both Web and PDF reports
        const orderedColors = [
            '#00a758', // color-green
            '#0000c1', // color-blue
            '#ec6453', // color-coral
            '#282B3E', // color-navy
            '#D77D28', // color-gold-dark-1
            '#604584', // color-violet
            '#09847B', // color-turquoise-dark-3
            '#830A10', // color-coral-dark-3
            '#836AA6', // color-violet-light-2
            '#004427', // color-green-dark-3
            '#2d69ff', // color-blue-light-2
            '#261848', // color-violet-dark-3
            '#D03A39', // color-coral-dark-1
            '#00009a', // color-blue-dark-1
            '#046138', // color-green-dark-2
            '#08a298', // color-turquoise-dark-2
            '#000060', // color-blue-dark-3
            '#8e90a2', // color-navy-light-4
            '#331A53', // color-violet-dark-2
            '#1e1ee5', // color-blue-light-1
            '#9D8DBC', // color-violet-light-3
            '#5e6073', // color-navy-light-3
            '#ce7612', // color-gold-dark-2
            '#A00E18', // color-coral-dark-2
            '#06874e', // color-green-dark-1
            '#34284b', // color-navy-light-1
            '#6F5693', // color-violet-light-1
            '#000082', // color-blue-dark-2
            '#5e6073', // color-navy-light-3
            '#4C336B', // color-violet-dark-1
            '#a75900', // color-gold-dark-3
        ];

        const options: Highcharts.Options = {
            title: { text: '' },
            chart: {
                style: { fontFamily: 'Manulife JH Sans' },
                width: size,
                height: size,
                type: 'pie',
                margin: [0, 10, 0, 0]
            },
            credits: { enabled: false },
            legend: { enabled: false },
            tooltip: {
                pointFormat: ''
            },
            plotOptions: {
                pie: {
                    size: '100%',
                    innerSize: '40%',
                    slicedOffset: 0,
                    allowPointSelect: false,
                    enableMouseTracking: false,
                    borderWidth: 0.5,
                    borderColor: '#fff',
                    animation: false,
                    colors: orderedColors,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [{ type: 'pie', data }]
        };

        return (
            <div className='allocation-tile'>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />

                <div className='allocation-table'>
                    <h4 className='chart-title'>{title}</h4>

                    {allocations.map((a, i) => (
                        <div key={i} className='allocation-row'>
                            <span
                                className='allocation-color'
                                style={{ backgroundColor: orderedColors[i % orderedColors.length] }}
                            />
                            <span className='allocation-name'>{a.name}</span>
                            <span className='allocation-percent'>{t(a.pct, 'pctMax1')}</span>
                        </div>
                    ))}

                    <span className='chart-disclaimer'>
                        {(productType === "GIF" || investmentType === "MMF") ? t('report.allocation.chartDisclaimerNonMPIP') : t('report.allocation.chartDisclaimer')}
                    </span>
                </div>
            </div>
        );
    }
}
