/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import theme from '../../styles/theme.pdf.json';

interface PropsShape {
    report: repr.Report
}

export default function FundProfileTitlePage(props: PropsShape) {
    const { report } = props;

    let bgImage; 
    let bgPosition = `unset`;

    if(report.investmentType === 'MMF'){
        bgImage = `url(img/mmf/pools-cover-image.jpg)`;
    }else if(report.investmentType === 'Seg'){
        report.productType === "GIF" ? bgImage = `url(img/gia/pools-cover-edit.jpg)` : bgImage = `url(img/seg/pool-details-edit.jpg)`;
    }else{
        bgImage = `url(img/gia/pools-cover-image.jpg)`;
    }

    return (
        <section
            className="page-full page--fund-profile-title"
            css={css`
                page: runningHeadersOnlyInverse;
                background-image: ${bgImage};
                background-repeat: no-repeat;
                background-size: cover;
                background-position: ${bgPosition};
            `}
        >
            <div className="main-full" css={css`padding-top: 3in;`}>
                <h1 className="section-title" css={css`font-size: ${theme['$font-size-xlarge']};`}>
                    {(report.productType === "GIF") ? t('report.fundProfile.headingGIF') : (report.investmentType === "MMF") ? 
                    t('report.fundProfile.headingMMF'): t('report.fundProfile.heading')}
                </h1>
            </div>
        </section>
    );
}
